import React, { useState, useEffect, useRef, useCallback } from 'react';
import { marked } from 'marked';
import './ExpandableChat.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faCircleArrowUp, faUser, faTrash, faStar, faThumbsUp, faThumbsDown, faFileWord, faCircle, faMicrophone } from '@fortawesome/free-solid-svg-icons';
import './translations';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';

function ExpandableChat({ userName, title, icon, expanded, sessionId, language, resetSessionId, chatPrefill, onCloseChat, onOpenChat, onResetMessages }) {
  const [vips, setVips] = useState([]);
  const [refreshCount, setRefreshCount] = useState(0);
  const [scrollBottom, setScrollBottom] = useState(0);
  const [events, setEvents] = useState([]);
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState('chat');
  const [messages, setMessages] = useState([{ text: t("chat_welcome_message"), sender: 'bot', isHtml: true }]);
  const [newMessage, setNewMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [calendarLoading, setCalendarLoading] = useState(false);
  const chatContentRef = useRef(null);
  const today = new Date().toISOString().split('T')[0];
  const [selectedDate, setSelectedDate] = useState(today);
  const textAreaRef = useRef(null);
  const currentBotMessage = useRef('');
  const [isAudioLoading, setIsAudioLoading] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const audioContextRef = useRef(null);
  const mediaStreamSourceRef = useRef(null);
  const processorRef = useRef(null);
  const audioBufferRef = useRef([]);

  const modifyLinks = (htmlText) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlText, 'text/html');

    const links = doc.querySelectorAll('a');
    links.forEach(link => {
      link.setAttribute('target', '_blank');
      link.setAttribute('rel', 'noopener noreferrer');
    });

    return doc.body.innerHTML;
  };

  const renderMessage = (text) => {
    const rawHtml = marked.parse(text);
    return modifyLinks(rawHtml);
  };

  const formatDateTime = (dateTimeStr) => {
    const dateTime = new Date(dateTimeStr);
    const date = dateTime.toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' });
    const time = dateTime.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit', hour12: false });
    return { date, time };
  };

  const fetchEvents = useCallback(async () => {
    setEvents([]);
    setCalendarLoading(true);
    try {
      const response = await fetch(`/api/fetch_calendar_events?date=${selectedDate}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setEvents(data.events);
    } catch (error) {
      console.error('Error fetching events:', error);
    } finally {
      setCalendarLoading(false);
    }
  }, [selectedDate]);

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const handleEventClick = (event) => {
    const { summary, description, start, end } = event;
    const { date, time: startTime } = formatDateTime(start.dateTime);
    const { time: endTime } = formatDateTime(end.dateTime);

    // This is what we want to send to the server
    const apiMessage = `Ich habe folgenden Kalendereintrag. Erstelle mir nützliche Informationen, um für den Termin vorbereitet zu sein:
Titel: ${summary}
Beschreibung: ${description}
Datum: ${date}
Start: ${startTime}
Ende: ${endTime}`;

    // Switch to chat tab
    setSelectedTab('chat');

    // Put the event details into the input box
    setNewMessage(apiMessage);

    // Then call the snippet-based send
    setTimeout(() => {
      handleSendMessage();
    }, 0);
  };

  const toggle = () => {
    if (expanded && onCloseChat) {
      onCloseChat();
    } else if (!expanded && onOpenChat) {
      onOpenChat();
    }
  };

  const resetMessage = () => {
    setMessages([
      {
        text: t("chat_welcome_message"),
        sender: 'bot',
        isHtml: true
      }
    ]);
    setNewMessage('');
    resetSessionId();
    onResetMessages();
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto';
    }
  };

  useEffect(() => {
    if (chatContentRef.current) {
      chatContentRef.current.scrollTop = chatContentRef.current.scrollHeight;
    }
  }, [messages, scrollBottom]);

  useEffect(() => {
    setMessages([
      {
        text: t("chat_welcome_message"),
        sender: 'bot',
        isHtml: true
      }
    ]);
  }, [t]);

  useEffect(() => {
    if (selectedTab === 'calendar' && selectedDate != null) {
      fetchEvents();
    }
  }, [selectedDate, selectedTab, fetchEvents]);

  useEffect(() => {
    const fetchVIPs = async () => {
      try {
        const response = await fetch(`/api/vip?user_name=${encodeURIComponent(userName)}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setVips(data);
      } catch (error) {
        console.error('Failed to fetch VIPs:', error);
      }
    };

    fetchVIPs();
  }, [refreshCount, userName]);

  useEffect(() => {
    if (expanded && chatPrefill) {
      setNewMessage(chatPrefill);
    }
  }, [expanded, chatPrefill]);

  const handleCreateVIP = async (message) => {
    const vipData = {
      user_name: userName,
      prompt: message.text
    };

    try {
      const response = await fetch(`/api/vip`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(vipData)
      });

      const data = await response.json();
      console.log(data.message);
    } catch (error) {
      console.error('Error posting VIP:', error);
    }
    setRefreshCount((current) => current + 1);
  };

  const handleDeleteVip = async (vipId) => {
    try {
      const response = await fetch(`/api/vip/${vipId}`, { method: 'DELETE' });
      if (!response.ok) {
        throw new Error('Failed to delete VIP');
      }
      setVips((currentVips) => currentVips.filter((vip) => vip.id !== vipId));
    } catch (error) {
      console.error('Error deleting VIP:', error);
    }
    setRefreshCount((current) => current + 1);
  };

  const handleEventClickVip = (message) => {
    setSelectedTab('chat');
    setNewMessage(message);

    // Adjust the textarea height dynamically
    setTimeout(() => {
      const textarea = textAreaRef.current;
      if (textarea) {
        textarea.style.height = 'auto'; // Reset height first
        let newHeight = textarea.scrollHeight;

        if (newHeight <= 28) {
          newHeight = 28;
          textarea.style.overflowY = 'hidden';
        } else if (newHeight > 150) {
          newHeight = 150;
          textarea.style.overflowY = 'auto';
        } else {
          textarea.style.overflowY = 'hidden';
        }
        textarea.style.height = newHeight + 'px';
      }
    }, 0);
    setScrollBottom((current) => current + 1);
  };

  const generateWordDocument = async (messages) => {
    try {
      const response = await fetch('/api/generate_word_document', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ messages }),
      });

      if (!response.ok) {
        throw new Error('Failed to generate document');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'ChatHistory.docx';
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      console.error('Error generating document:', error);
    }
  };

  const handleInput = (e) => {
    setNewMessage(e.target.value);

    const textarea = textAreaRef.current;
    if (!textarea) return;

    // Always reset to 'auto' first
    textarea.style.height = 'auto';

    let newHeight = textarea.scrollHeight;

    if (newHeight <= 28) {
      newHeight = 28;
      textarea.style.overflowY = 'hidden';
    } else if (newHeight > 150) {
      newHeight = 150;
      textarea.style.overflowY = 'auto';
    } else {
      textarea.style.overflowY = 'hidden';
    }
    textarea.style.height = newHeight + 'px';
  };

  const handleSendMessage = async () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto';
    }
    if (newMessage.trim()) {
      const userMessage = { text: newMessage, sender: 'user' };
      setMessages(prevMessages => [...prevMessages, userMessage]);
      const messageToSend = newMessage;
      setNewMessage('');
      setLoading(true);
      currentBotMessage.current = "";

      try {
        const eventSource = new EventSource(
          `/api/chat?user_input=${encodeURIComponent(messageToSend)}&session_id=${sessionId}&language=${language.language}`
        );

        eventSource.onmessage = (event) => {
          const chunk = event.data;
          if (chunk !== 'end of response') {
            setLoading(false);
            const parsedChunk = chunk.replace(/<newline>/g, '\n');
            currentBotMessage.current += parsedChunk;
            setMessages(messages => {
              const lastMessage = messages[messages.length - 1];
              if (lastMessage && lastMessage.sender === 'bot') {
                return [...messages.slice(0, -1), { ...lastMessage, text: currentBotMessage.current }];
              } else {
                return [...messages, { text: currentBotMessage.current, sender: 'bot' }];
              }
            });
          } else {
            eventSource.close();
          }
        };

        eventSource.onerror = (error) => {
          console.error('Error fetching response:', error);
          setMessages(prevMessages => [
            ...prevMessages,
            { text: 'Failed to get response from server.', sender: 'bot' }
          ]);
          setLoading(false);
          eventSource.close();
        };

        eventSource.addEventListener('end', () => {
          setMessages(messages => {
            const lastMessage = messages[messages.length - 1];
            if (lastMessage && lastMessage.sender === 'bot') {
              return [...messages.slice(0, -1), { text: currentBotMessage.current, sender: 'bot' }];
            } else {
              return [...messages, { text: currentBotMessage.current, sender: 'bot' }];
            }
          });
          setLoading(false);
          eventSource.close();
        });

      } catch (error) {
        console.error('Error fetching response:', error);
        setMessages(prevMessages => [
          ...prevMessages,
          { text: 'Failed to get response from server.', sender: 'bot' }
        ]);
        setLoading(false);
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !loading) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleRecordClick = async () => {
    if (isRecording) {
      // Stop recording
      if (processorRef.current && mediaStreamSourceRef.current) {
        mediaStreamSourceRef.current.disconnect();
        processorRef.current.disconnect();
      }

      // Stop the media stream to release the microphone
      if (audioContextRef.current && audioContextRef.current.state !== 'closed') {
        audioContextRef.current.close(); // Close the AudioContext
      }
      if (mediaStreamSourceRef.current && mediaStreamSourceRef.current.mediaStream) {
        mediaStreamSourceRef.current.mediaStream.getTracks().forEach(track => track.stop());
      }

      setIsRecording(false);
      console.log("stopped!");

      // Process and send the recorded audio data to the backend
      processAudioData(audioBufferRef.current);
    } else {
      // Start recording using Web Audio API
      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        alert('Your browser does not support audio recording.');
        return;
      }

      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        audioContextRef.current = audioContext;
        mediaStreamSourceRef.current = audioContext.createMediaStreamSource(stream);
        processorRef.current = audioContext.createScriptProcessor(4096, 1, 1);

        mediaStreamSourceRef.current.connect(processorRef.current);
        processorRef.current.connect(audioContext.destination);

        audioBufferRef.current = [];

        processorRef.current.onaudioprocess = (e) => {
          const inputBuffer = e.inputBuffer.getChannelData(0);
          audioBufferRef.current.push(new Float32Array(inputBuffer));
        };

        setIsRecording(true);
        console.log("started recording");
      } catch (error) {
        console.error('Error accessing microphone:', error);
        alert('Could not access your microphone. Please check permissions.');
      }
    }
  };

  const processAudioData = async (buffer) => {
    console.log('Processing audio buffer:', buffer);

    setIsAudioLoading(true); // Start loader

    const wavBlob = encodeWAV(buffer);
    const reader = new FileReader();
    reader.readAsDataURL(wavBlob);

    reader.onloadend = async () => {
      const base64data = reader.result.split(',')[1];

      try {
        const response = await fetch('/api/translate_audio_to_text', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ audio_file: base64data }),
        });

        if (!response.ok) {
          throw new Error('Failed to process audio');
        }

        const data = await response.json();
        if (data && data.user_input) {
          setNewMessage(data.user_input);
        } else {
          console.error('Invalid response from API');
        }
      } catch (error) {
        console.error('Error processing audio:', error);
      } finally {
        setIsAudioLoading(false); // Stop loader
      }
    };
  };

  const encodeWAV = (buffers) => {
    const sampleRate = audioContextRef.current.sampleRate;
    const bufferLength = buffers.length * buffers[0].length;
    const buffer = new ArrayBuffer(44 + bufferLength * 2);
    const view = new DataView(buffer);

    const writeString = (view, offset, string) => {
      for (let i = 0; i < string.length; i++) {
        view.setUint8(offset + i, string.charCodeAt(i));
      }
    };

    writeString(view, 0, 'RIFF');
    view.setUint32(4, 36 + bufferLength * 2, true);
    writeString(view, 8, 'WAVE');
    writeString(view, 12, 'fmt ');
    view.setUint32(16, 16, true);
    view.setUint16(20, 1, true);
    view.setUint16(22, 1, true); // Mono channel
    view.setUint32(24, sampleRate, true);
    view.setUint32(28, sampleRate * 2, true);
    view.setUint16(32, 2, true);
    view.setUint16(34, 16, true);
    writeString(view, 36, 'data');
    view.setUint32(40, bufferLength * 2, true);

    let offset = 44;
    buffers.forEach((buffer) => {
      for (let i = 0; i < buffer.length; i++, offset += 2) {
        const s = Math.max(-1, Math.min(1, buffer[i]));
        view.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7FFF, true);
      }
    });

    return new Blob([view], { type: 'audio/wav' });
  };

  return (
    <div className="expandable-chat-container">
      <div className="expandable-header" onClick={toggle}>
        <span>
          <FontAwesomeIcon
            icon={icon}
            className='expandable-icon'
          />
          {` ${title}`}
        </span>
        <FontAwesomeIcon
          icon={expanded ? faAngleUp : faAngleDown}
          className="arrow"
        />
      </div>
      <div className={`tab-content ${expanded ? 'open' : ''}`}>
        {expanded && (
          <>
            <div className="chat-tabs-header">
              <button
                className={`chat-tab-button ${selectedTab === 'chat' ? 'active' : ''}`}
                onClick={() => setSelectedTab('chat')}
              >
                {t('chat-tab')}
              </button>
              <button
                className={`chat-tab-button ${selectedTab === 'calendar' ? 'active' : ''}`}
                onClick={() => setSelectedTab('calendar')}
              >
                {t('calendar-tab')}
              </button>
              <button
                className={`chat-tab-button ${selectedTab === 'vip' ? 'active' : ''}`}
                onClick={() => setSelectedTab('vip')}
              >
                {t('very-important-prompts')}
              </button>
            </div>
            <div className="tabs-content">
              {selectedTab === 'chat' && (
                <>
                  <div className="chat-content" ref={chatContentRef}>
                    {messages.map((message, index) => (
                      <div
                        key={index}
                        className={`message-icon-container ${message.sender}`}
                      >
                        {message.sender === 'bot' && (
                          <div className="message-icon">
                            E
                          </div>
                        )}
                        {message.sender === 'user' && (
                          <div
                            className="star-icon"
                            onClick={() => handleCreateVIP(message)}
                          >
                            <FontAwesomeIcon icon={faStar} />
                          </div>
                        )}
                        <div className={`chat-message ${message.sender}`}>
                          <div className='markdown-container'>
                            <ReactMarkdown
                              remarkPlugins={[remarkGfm]}
                              rehypePlugins={[rehypeRaw]}
                            >
                              {renderMessage(message.text)}
                            </ReactMarkdown>
                            {index !== 0 && message.sender === 'bot' && (
                              <div className='bot-response-reaction-container'>
                                <div className='thumps-container'>
                                  <div className="thumps-icon">
                                    <FontAwesomeIcon icon={faThumbsUp} />
                                  </div>
                                  <div className="thumps-icon">
                                    <FontAwesomeIcon icon={faThumbsDown} />
                                  </div>
                                </div>
                                <div className="word-icon">
                                  <FontAwesomeIcon
                                    icon={faFileWord}
                                    onClick={() => generateWordDocument(messages.slice(1))}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        {message.sender === 'user' && (
                          <div className="user-icon">
                            <FontAwesomeIcon icon={faUser} />
                          </div>
                        )}
                      </div>
                    ))}
                    {loading && (
                      <div className="chat-bot-loader">
                        <div className="chat-loader"></div>
                        <span className="loading-text">
                          {t('load-response')}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className='chat-input-wrapper'>
                    <div className="chat-input-container">
                      {isAudioLoading && <div className="audio-loader-expandable"></div>}
                      <textarea
                        ref={textAreaRef}
                        className="chat-textarea"
                        value={newMessage}
                        onChange={handleInput}
                        onKeyPress={handleKeyPress}
                        placeholder={isAudioLoading ? "" : t('ask_question')}
                        disabled={loading || isAudioLoading}
                        rows="1"
                      />
                      <button
                        onClick={handleSendMessage}
                        className="send-button"
                        disabled={loading || isAudioLoading}
                      >
                        <FontAwesomeIcon
                          icon={faCircleArrowUp}
                          className='send-icon'
                        />
                      </button>
                      <button
                        className="send-button"
                        onClick={handleRecordClick}
                        disabled={loading || isAudioLoading}
                      >
                          {isRecording ? (
                            <FontAwesomeIcon icon={faCircle} className='record-icon-recording' />
                          ) : (
                            <FontAwesomeIcon icon={faMicrophone} className='record-icon' />
                          )}
                      </button>
                    </div>
                    <div className='clear-chat-button-container'>
                      <button
                        onClick={resetMessage}
                        className='clear-chat-button'
                        title={t('reset-chat')}
                        disabled={loading || isAudioLoading}
                      >
                        <FontAwesomeIcon
                          icon={faTrash}
                          className='clear-chat-icon'
                        />
                      </button>
                    </div>
                  </div>
                </>
              )}
              {selectedTab === 'calendar' && (
                <div className="calendar-content">
                  <label
                    htmlFor="dateInput"
                    style={{
                      fontWeight: '600',
                      marginBottom: '5px',
                      display: 'block',
                      fontSize: '15px'
                    }}
                  >
                    {t('date')}
                  </label>
                  <input
                    type="date"
                    id="dateInput"
                    className='calendar-date-input'
                    value={selectedDate}
                    onChange={handleDateChange}
                  />
                  {calendarLoading && (
                    <div className="chat-bot-loader">
                      <div className="chat-loader"></div>
                      <span className="loading-text">{t('load-calendar')}</span>
                    </div>
                  )}
                  {events.length > 0 ? (
                    <div className='event-parent-container'>
                      {events.map((event, index) => {
                        const { time: startTime } = formatDateTime(event.start.dateTime);
                        const { time: endTime } = formatDateTime(event.end.dateTime);
                        return (
                          <div
                            className='event-parent'
                            key={index}
                            onClick={() => handleEventClick(event)}
                          >
                            <p className='event-index'>
                              {startTime} - {endTime} Uhr
                            </p>
                            <p className='event-title'>
                              {event.summary}
                            </p>
                            <div className='event-details-bottom'>
                              <div className='event-details-bottom-content'>
                                <p className='event-details-title'>
                                  {t('description')}
                                </p>
                                <p>{event.description}</p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    !calendarLoading && <p>{t('no-events')}</p>
                  )}
                </div>
              )}
              {selectedTab === 'vip' && (
                <div>
                  {vips.length > 0 ? (
                    <div className='vip-item-parent-container'>
                      {vips.map((vip, index) => (
                        <div className="vip-item" key={vip.id}>
                          <div
                            className='vip-item-content'
                            onClick={() => handleEventClickVip(vip.prompt)}
                          >
                            <div className='vip-item-header'>#{index + 1}</div>
                            <div>{vip.prompt}</div>
                          </div>
                          <button
                            onClick={() => handleDeleteVip(vip.id)}
                            className='delete-vip-button'
                          >
                            <FontAwesomeIcon
                              icon={faTrash}
                              className='delete-vip-icon'
                            />
                          </button>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p>Keine gespeicherten Prompts</p>
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ExpandableChat;
