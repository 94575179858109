import React, { useState } from 'react';
import './Login.css';
import logo from './bank-logo.svg';


function Login({ onLogin }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const validCredentials = [
    { username: "memo", password: "earlylion" },
    { username: "memo1", password: "earlylion" },
    { username: "memo2", password: "earlylion" },
    { username: "frank", password: "earlylion" },
    { username: "frank2", password: "earlylion" },
    { username: "andreas", password: "earlylion" },
    { username: "andreas2", password: "earlylion" },
    { username: "hayoz1", password: "earlylion" },
    { username: "hayoz2", password: "earlylion" },
    { username: "hayoz3", password: "earlylion" },
    { username: "hayoz4", password: "earlylion" },
    { username: "hayoz5", password: "earlylion" },
    { username: "gebi", password: "earlylion" },
    { username: "gebi2", password: "earlylion" },
    { username: "karim", password: "earlylion" },
    { username: "karim2", password: "earlylion" },
    { username: "cedric", password: "earlylion" },
    { username: "cedric1", password: "earlylion" },
    { username: "cedric2", password: "earlylion" },
    { username: "miles", password: "earlylion" },
    { username: "miles1", password: "earlylion" },
    { username: "miles2", password: "earlylion" },
    { username: "angelo", password: "earlylion" },
    { username: "angelo1", password: "earlylion" },
    { username: "angelo2", password: "earlylion" },
    { username: "noveras2", password: "earlylion" },
    { username: "noveras2", password: "earlylion" },
    { username: "Jan Berchtold", password: "vz_test_2025" },
    { username: "Mathias Boetschi", password: "vz_test_2025" },
    { username: "manuel_beck", password: "earlylion" },
    { username: "manuel_beck2", password: "earlylion" },
    { username: "jannik_zimmermann", password: "earlylion" },
    { username: "jannik_zimmermann2", password: "earlylion" },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    setTimeout(() => {
      const isValid = validCredentials.some(cred => cred.username === username && cred.password === password);

      if (isValid) {
        onLogin(true, username);
        setError('');
      } else {
        onLogin(false);
        setError('Falscher Benutzername oder Passwort.');
      }

      setLoading(false);
    }, 1000);
  };

  return (
    <div className='login-form'>
        <img src={logo} alt="login-logo" className='login-logo'/>
        <h1>Login</h1>
        <form onSubmit={handleSubmit}>
            <div className='login-input'>
                <input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Benutzername"
                />
            </div>
            <div className='login-input'>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder='Passwort'
                />
            </div>
            <div className='passwort-forgotten'>
              Passwort vergessen?
            </div>
            <div className='submit-area'>
            <button className='submit-login' type="submit" disabled={loading}>
              Einloggen
              {loading && <span className="login-loader"></span>}
            </button>
            </div>
            {error && <div className="login-error">{error}</div>}
            <div className='new-member'>
              Neu? Jetzt registrieren!
            </div>
        </form>
    </div>
  );
}

export default Login;
